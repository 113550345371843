<template>
  <div>
    <van-cell center style="padding: 4px 16px">
      <template slot="title">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="value"
            :options="option"
            @change="medicalDrop"
          />
        </van-dropdown-menu>
      </template>
      <template slot="right-icon">
        <div style="dispaly: flex" @click="onDateSort">
          日期排序
          <van-icon
            slot="right-icon"
            class="iconfont"
            :name="sortIcon"
            class-prefix="icon"
            color="#969799"
          />
        </div>
      </template>
    </van-cell>
    <van-list>
      <van-cell-group v-for="item in list" :key="item.index">
        <van-cell>
          <template slot="title">
            <div>
              {{ item.payWayName }}
              <div style="float: right">
                <span style="color: #57c4b7">{{ item.prepayCost }}</span
                >元
              </div>
            </div>
          </template>
          <template slot="label">
            <div>
              {{ item.operTime }}
              <div style="float: right">
                <span v-if="item.state == 0">已取消</span>
                <span v-if="item.state == 1">未支付</span>
                <span v-if="item.state == 3">已退款</span>
                <span v-else>已支付</span>
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import { Toast } from "vant";
import store from "../../store";
// let inpatientSeriNo = store.getters["store/inpatientSeriNo"];
// let patientid = store.getters["store/patientid"];
// patientid = "0000008866";
var nowDate = new Date();
export default {
  data() {
    return {
      value: 0,
      time: "",
      option: [
        {
          text: "全部",
          value: 0,
        },
        {
          text: "1个月内",
          value: 1,
        },
        {
          text: "3个月内",
          value: 2,
        },
      ],
      sortIcon: "caozuo-paixu-jiangxu", //caozuo-paixu-jiangxu、paixu-shengxu
      list: [],
    };
  },
  created() {
    let lw = new Date(nowDate - 1000 * 60 * 60 * 24 * 30); //最后一个数字30可改，30天的意思
    let lastY = lw.getFullYear();
    let lastM = lw.getMonth() + 1;
    let lastD = lw.getDate();
    this.time =
      lastY +
      "-" +
      (lastM < 10 ? "0" + lastM : lastM) +
      "-" +
      (lastD < 10 ? "0" + lastD : lastD); //九十天之前日期
    console.log(this.time);
    this.onload();
  },
  methods: {
    async onload() {
      let idcard = store.getters["store/idcard"];
      let userId = store.getters["store/userId"];
      console.log(userId);
      // let idcard = store.getters["store/idcard"];
      console.log(idcard);
      // console.log(idcard);
      await ajax
        .post("/Api/InHospitalService/GetUserInHospRecords", {
          userID: userId,
          userName: this.userName,
          idCard: idcard,
          // idCard: idcard,
          minDate: this.time,
          machineName: "wechat",
        })
        .then(async (res) => {
          this.list = res.data.result;
          console.log(this.list);
        })
        .catch((err) => {
          Toast.clear();
          Toast("出现未知错误：" + err);
        });
    },
    medicalDrop() {
      if (this.value == 0) {
        this.onload();
        console.log(this.time);
      }
      if (this.value == 1) {
        let lw = new Date(nowDate - 1000 * 60 * 60 * 24 * 30); //最后一个数字30可改，30天的意思
        let lastY = lw.getFullYear();
        let lastM = lw.getMonth() + 1;
        let lastD = lw.getDate();
        this.time =
          lastY +
          "-" +
          (lastM < 10 ? "0" + lastM : lastM) +
          "-" +
          (lastD < 10 ? "0" + lastD : lastD); //九十天之前日期
        this.onload();
        console.log(this.time);
      } else {
        let lw = new Date(nowDate - 1000 * 60 * 60 * 24 * 90); //最后一个数字30可改，30天的意思
        let lastY = lw.getFullYear();
        let lastM = lw.getMonth() + 1;
        let lastD = lw.getDate();
        this.time =
          lastY +
          "-" +
          (lastM < 10 ? "0" + lastM : lastM) +
          "-" +
          (lastD < 10 ? "0" + lastD : lastD); //九十天之前日期
        this.onload();
        console.log(this.time);
      }
    },
    sortTime(obj1, obj2) {
      return obj2.tradeDate - obj1.tradeDate;
    },
    sortTime2(obj1, obj2) {
      return obj1.tradeDate - obj2.tradeDate;
    },
    onDateSort() {
      //时间排序
      if (this.sortIcon === "caozuo-paixu-jiangxu") {
        this.sortIcon = "paixu-shengxu";
        this.list.sort(this.sortTime);
      } else {
        this.sortIcon = "caozuo-paixu-jiangxu";
        this.list.sort(this.sortTime2);
      }
    },
  },
};
</script>


<style  scoped>
</style>
